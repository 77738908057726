import { contratosInstance } from '../instance';
import { SuperUserRoutes } from '../routes';

export const SuperUserEPs = (createEndpoint) => {
  return {
    getCouponsEP: createEndpoint(contratosInstance, SuperUserRoutes.getCoupons),
    searchDateEP: createEndpoint(contratosInstance, SuperUserRoutes.searchDate),
    getUsedCouponsEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.getUsedCoupons,
    ),
    getConservancyRecordsEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.getConservancyRecords,
    ),
    createCouponEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.createCoupon,
    ),
    searchContractsEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.searchContracts,
    ),
    searchContractsUserEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.searchContractsUser,
    ),
    searchFileUrlEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.searchFileUrl,
    ),
    getApiValidatedEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.getApiValidated,
    ),
    getApiRequestsEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.getApiRequests,
    ),
    updateApiUserEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.updateApiUser,
    ),
    createApiUserEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.createApiUser,
    ),
    disableCouponEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.disableCoupon,
    ),
    getContractCountEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.getContractCount,
    ),
    getUserCountEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.getUserCount,
    ),
    getContractsByMonthEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.getContractsByMonth,
    ),
    getContractsInfoEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.getContractsInfo,
    ),
    getUsersInfoEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.getUsersInfo,
    ),
    getUsersByMonthEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.getUsersByMonth,
    ),
    getTokenUsersEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.getTokenUsers,
    ),
    updateTokenEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.updateToken,
    ),
    getSignerByDateEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.getSignerByDate,
    ),
    updateUserSuperAdminEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.updateUser,
    ),
    deleteUserEP: createEndpoint(contratosInstance, SuperUserRoutes.deleteUser),
    getSignersOrAdminsEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.getSignersOrAdmins,
    ),
    createContractsEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.createContracts,
    ),
    updateSpUserCouponEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.updateSpUserCoupon,
    ),
    deleteRecipientSuperAdminEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.deleteRecipientSuperAdmin,
    ),
    addCopiedUserSuperAdminEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.addCopiedUserSuperAdmin,
    ),
    updateSignerSuperUserEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.updateSignerSuperUser,
    ),
    assignNewSignerToDocEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.assignNewSignerToDoc,
    ),
    getExternalApiPermissionsEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.getExternalApiPermissions,
    ),
    addExternalApiEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.addExternalApi,
    ),
    getExternalApiInfoByIdEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.getExternalApiInfoById,
    ),
    updateExternalApiEP: createEndpoint(
      contratosInstance,
      SuperUserRoutes.updateExternalApi,
    ),
  };
};
