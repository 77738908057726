import { superUserServices } from '../API/services/superUserServices';
import state from '../state';

const SuperUserActions = {
  /**
   * Get Coupons for Super User
   * @returns {Promise}
   */
  async getCoupons() {
    try {
      const { data } = await superUserServices.getCouponsService();
      state.select(['myCoupons']).set(data);
      return data;
    } catch (error) {
      return error.response;
    }
  },

  /**
   * Get Coupons for Super User
   * @returns {Promise}
   */
  async getUsedCoupons() {
    const res = await superUserServices.getUsedCouponsService();
    return res;
  },

  /**
   * Get Api Request for Super User
   * @returns {Promise}
   */
  async getConservancyRecords() {
    const res = await superUserServices.getConservancyRecordsService();
    return res;
  },

  /**
   * Create Coupon
   * @param properties
   * @returns {Promise}
   */
  async createCoupon(properties) {
    const payload = {
      code: properties.code,
      contracts: properties.contracts,
    };
    const res = await superUserServices.createCouponService(payload);
    return res;
  },

  /**
   * @param id
   * @returns {Promise}
   */
  async searchContracts(id) {
    const res = await superUserServices.searchContractsService({}, { id });
    return res;
  },

  /**
   * @param id
   * @returns {Promise}
   */
  async searchContractsUser(id) {
    const res = await superUserServices.searchContractsUserService({}, { id });
    return res;
  },

  /**
   * @param id
   * @returns {Promise}
   */
  async searchFileUrl(id) {
    const response = await superUserServices.searchFileUrlService({}, { id });
    return response;
  },

  /**
   * @param id
   * @returns {Promise}
   */
  async searchDate(id) {
    const response = await superUserServices.searchDateService({}, { id });
    return response;
  },

  /**
   * @returns {Promise}
   */
  async getApiValidated() {
    const response = await superUserServices.getApiValidatedService();
    return response;
  },

  /**
   * @returns {Promise}
   */
  async getApiRequests() {
    const response = await superUserServices.getApiRequestsService();
    return response;
  },

  /**
   * @param id
   * @returns {Promise}
   */
  async updateApiUser(id) {
    const response = await superUserServices.updateApiUserService({}, { id });
    return response;
  },

  /**
   * @param cer
   * @returns {Promise}
   */
  async createApiUser(cer) {
    const formData = new FormData();
    formData.append('cer', cer);
    const { data } = await superUserServices.createApiUserService(formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
  },

  /**
   * @param couponId
   * @returns {Promise}
   */
  async disableCoupon(couponId) {
    try {
      const response = await superUserServices.disableCouponService(
        {},
        {},
        { couponId },
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },

  /**
   * @returns {Promise}
   */
  async getContractCount(date) {
    const response = await superUserServices.getContractCountService(
      {},
      { date },
    );
    return response;
  },

  async getUserCount(date) {
    const response = await superUserServices.getUserCountService({}, { date });
    return response;
  },

  async getContractsByMonth(date) {
    const response = await superUserServices.getContractsByMonthService(
      {},
      { date },
    );
    return response;
  },

  async getContractsInfo() {
    const response = await superUserServices.getContractsInfoService();
    return response;
  },

  async getUsersInfo() {
    const response = await superUserServices.getUsersInfoService();
    return response;
  },

  async getUsersByMonth(date) {
    const response = await superUserServices.getUsersByMonthService(
      {},
      { date },
    );
    return response;
  },

  async getTokenUsers() {
    const response = await superUserServices.getTokenUsersService();
    return response;
  },
  async updateToken(tokenId, legalRfc) {
    const payload = {
      tokenId,
      legalRfc,
    };
    const response = await superUserServices.updateTokenService(payload);
    return response;
  },

  async getSinersByDate(date, reportValue) {
    const response = await superUserServices.getSinersByDateService(
      { params: { reportValue } },
      { date },
    );
    return response;
  },

  async updateUserSuperAdmin(data) {
    const response = await superUserServices.updateUserServiceSuperAdmin(data);
    return response;
  },

  async deleteUser(legalRfc) {
    const response = await superUserServices.deleteUserService({ legalRfc });
    return response;
  },

  async getSignersOrAdmins(legalRfc, userType) {
    const response = await superUserServices.getSignersOrAdminsService(
      { params: { userType } },
      { legalRfc },
    );
    return response;
  },

  async createContracts(data) {
    const response = await superUserServices.createContractsService(data);
    return response;
  },

  async updateSpUserCoupon(data) {
    const response = await superUserServices.updateSpUserCouponService(data);
    return response;
  },

  async deleteRecipientSuperAdmin(data) {
    const response = await superUserServices.deleteRecipientSuperAdminService(
      data,
    );
    return response;
  },

  async addCopiedUserSuperAdmin(data) {
    const response = await superUserServices.addCopiedUserSuperAdminService(
      data,
    );
    return response;
  },
  async updateSignerSuperUser(data) {
    const response = await superUserServices.updateSignerSuperUserService(data);
    return response;
  },
  async assignNewSignerToDoc(data) {
    const response = await superUserServices.assignNewSignerToDocService(data);
    return response;
  },

  async getExternalApiPermissions() {
    const response = await superUserServices.getExternalApiPermissionsService();
    return response;
  },

  async addExternalApi(data) {
    const response = await superUserServices.addExternalApiService(data);
    return response;
  },

  async getExternalApiInfoById(externalApiId) {
    const response = await superUserServices.getExternalApiInfoByIdService(
      {},
      { externalApiId },
    );

    return response;
  },

  async updateExternalApi(data) {
    const response = await superUserServices.updateExternalApiService(data);
    return response;
  },
};

export default SuperUserActions;
