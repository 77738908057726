export const SuperUserRoutes = {
  updateToken: '/superadmin/update-token/',
  getTokenUsers: '/superadmin/getTokenUsers',
  getUsersByMonth: '/superadmin/countMonthUsers/:date',
  getUsersInfo: '/superadmin/getUsersInfo',
  getContractsInfo: '/superadmin/getContractsInfo',
  getContractsByMonth: '/superadmin/countMonthContracts/:date',
  getUserCount: '/superadmin/countUsers/:date',
  getContractCount: '/superadmin/countContracts/:date',
  disableCoupon: '/superadmin/updateCoupon/:couponId',
  createApiUser: '/superadmin/createUser/cer',
  updateApiUser: '/superadmin/authorize/:id',
  getApiRequests: '/superadmin/allApiRequests',
  getApiValidated: '/superadmin/apiUsers',
  searchDate: '/superadmin/contracts/date/:id',
  searchFileUrl: '/superadmin/contracts/fileUrl/:id',
  searchContractsUser: '/superadmin/contracts/searchUser/:id',
  searchContracts: '/superadmin/contracts/search/:id',
  createCoupon: '/superadmin/coupon/create',
  getConservancyRecords: '/superadmin/conservancy-records/list',
  getUsedCoupons: '/superadmin/coupons-used/list',
  getCoupons: '/superadmin/coupon/list',
  getSignerByDate: '/superadmin/get-signers/:date',
  updateUser: '/superadmin/updateUser',
  deleteUser: '/superadmin/deleteUser',
  getSignersOrAdmins: '/superadmin/signers-admins/:legalRfc',
  createContracts: '/superadmin/createContracts',
  updateSpUserCoupon: '/superadmin/update-sp-user-coupon',
  deleteRecipientSuperAdmin: '/superadmin/delete-recipient-sa',
  addCopiedUserSuperAdmin: '/superadmin/add-recipient-sa',
  updateSignerSuperUser: '/superadmin/update-signer-sa',
  assignNewSignerToDoc: '/superadmin/assign-new-signer-sa',
  getExternalApiPermissions: '/superadmin/get-external-api-permissions',
  addExternalApi: '/superadmin/add-external-api',
  getExternalApiInfoById: '/superadmin/get-external-api-info/:externalApiId',
  updateExternalApi: '/superadmin/update-external-api',
};
