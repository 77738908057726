import React, { Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { branch } from 'baobab-react/higher-order';
import { NotificationManager } from 'react-notifications';
import SuperUserActions from '../../../actions/SuperUser';
import Loader from '../../../components/Loader';
import { emailRegex } from '../../../utils/constants';
// Actions

const PERMISSIONS_API = [
  {
    id: 1,
    method: 'GET',
    route: '/v1/documents/get-document/:id',
  },
  {
    id: 2,
    method: 'POST',
    route: '/v1/documents/create-document',
  },
];

class AddExternalApi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      available: [],
      selected: [],
      rfc: '',
      email: '',
      origin: '',
      selectedRfcs: [],
      loading: false,
    };
  }

  async componentDidMount() {
    try {
      this.setState({ loading: true });
      const response = await SuperUserActions.getExternalApiPermissions();
      if (response?.data?.success) {
        return this.setState({ available: response.data?.permissions ?? [] });
      }
      return NotificationManager.error(
        response?.data?.error || 'Error al obtener permisos',
        'Atención',
        10000,
      );
    } catch (error) {
      this.setState({ loading: false });
      return NotificationManager.error(
        'Error al obtener permisos',
        'Atención',
        10000,
      );
    } finally {
      this.setState({ loading: false });
    }
  }

  isUrl = (str) => {
    try {
      new URL(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  moveToSelected = (perm) => {
    this.setState((prevState) => ({
      available: prevState.available.filter((p) => p.id !== perm.id),
      selected: [...prevState.selected, perm],
    }));
  };

  moveToAvailable = (perm) => {
    this.setState((prevState) => ({
      available: [...prevState.available, perm],
      selected: prevState.selected.filter((p) => p.id !== perm.id),
    }));
  };

  addRfcs = () => {
    if (!this.state.rfc) {
      return NotificationManager.error(
        'RFC no puede estar vacío',
        'Atención',
        10000,
      );
    }
    this.setState((prevState) => ({
      selectedRfcs: [...prevState.selectedRfcs, prevState.rfc.toUpperCase()],
      rfc: '',
    }));
  };

  removeRfc = (rfc) => {
    this.setState((prevState) => ({
      selectedRfcs: prevState.selectedRfcs.filter((r) => r !== rfc),
    }));
  };

  handleSubmit = async (e) => {
    try {
      const { selected, selectedRfcs, email, origin } = this.state;

      if (!email || !emailRegex.test(email)) {
        return NotificationManager.error('Email no válido', 'Atención', 10000);
      }

      if (!origin) {
        return NotificationManager.error(
          'Origen de petición no puede estar vacío',
          'Atención',
          10000,
        );
      }

      const isValidUrl = this.isUrl(origin);
      if (!isValidUrl) {
        return NotificationManager.error(
          'Origen de petición no es una URL válida',
          'Atención',
          10000,
        );
      }

      if (!selectedRfcs?.length) {
        return NotificationManager.error(
          'Debes agregar al menos un RFC',
          'Atención',
          10000,
        );
      }

      if (!selected?.length) {
        return NotificationManager.error(
          'Debes seleccionar al menos un permiso',
          'Atención',
          10000,
        );
      }

      this.setState({ loading: true });

      const response = await SuperUserActions.addExternalApi({
        permissions: selected,
        rfcs: selectedRfcs,
        email,
        origin,
      });
      if (!response?.data?.success) {
        return NotificationManager.error(
          response?.data?.error || 'Error al agregar external API',
          'Atención',
          10000,
        );
      }

      this.setState({
        selected: [],
        selectedRfcs: [],
        rfc: '',
        email: '',
        origin: '',
      });

      return NotificationManager.success(
        'API externa agregada correctamente',
        'Éxito',
        10000,
      );
    } catch (error) {
      return NotificationManager.error(
        'Error al agregar external API',
        'Atención',
        10000,
      );
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { session, location } = this.props;
    const { selected, selectedRfcs, loading, email, origin } = this.state;

    const isDisabled =
      !selected?.length || !selectedRfcs.length || !email || !origin;
    if (!session) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: location.pathname,
            },
          }}
        />
      );
    }

    if (loading) {
      return (
        <div className="center_loading">
          <Loader />
          <p>Cargando información</p>
        </div>
      );
    }

    return (
      <Fragment>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: 16,
          }}
        >
          <button
            type="submit"
            className="primary"
            onClick={this.handleSubmit}
            disabled={isDisabled}
          >
            Finalizar y Guardar
          </button>
        </div>
        <div className="permissions-container">
          {/* Available Permissions */}
          <div>
            <div
              style={{
                display: 'column',
                alignItems: 'center',
                gap: 16,
                width: 300,
              }}
            >
              <input
                required
                type="text"
                name="email"
                value={this.state.email}
                className="input_nc"
                placeholder="Email de contacto"
                onChange={(e) => this.setState({ email: e.target.value })}
              />
              <input
                required
                type="text"
                name="origin"
                value={this.state.origin}
                className="input_nc"
                placeholder="Origen de petición"
                onChange={(e) => this.setState({ origin: e.target.value })}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 16,
                }}
              >
                <input
                  type="text"
                  name="rfc"
                  value={this.state.rfc}
                  className="input_nc"
                  placeholder="RFC"
                  onChange={(e) => this.setState({ rfc: e.target.value })}
                />
                <button
                  type="submit"
                  className="primary"
                  onClick={this.addRfcs}
                >
                  <i className="fas fa-plus-circle" />
                </button>
              </div>
            </div>
            <ul style={{ padding: 0 }}>
              {this.state.selectedRfcs.map((rfc, i) => (
                <li
                  key={i}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: 10,
                  }}
                >
                  <small style={{ fontSize: '14px', color: '#6c6c6c' }}>
                    {rfc}
                  </small>
                  <i
                    className="fas fa-trash"
                    style={{ cursor: 'pointer', color: 'red' }}
                    onClick={() => this.removeRfc(rfc)}
                  ></i>
                </li>
              ))}
            </ul>
          </div>
          <div className="permissions-column">
            <h3>Permisos</h3>
            <ul>
              {this.state.available.map((perm) => (
                <li
                  key={perm.id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 10,
                  }}
                >
                  <small style={{ fontSize: '14px', color: '#6c6c6c' }}>
                    {perm.method} {perm.route}
                  </small>
                  <i
                    className="fas fa-arrow-right"
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.moveToSelected(perm)}
                  ></i>
                </li>
              ))}
            </ul>
          </div>

          {/* Action Buttons */}
          <div className="permissions-actions"></div>

          {/* Selected Permissions */}
          <div className="permissions-column">
            <h3>Permisos seleccionados</h3>
            <ul>
              {this.state.selected.map((perm) => (
                <li
                  key={perm.id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 10,
                  }}
                >
                  <i
                    className="fas fa-arrow-left"
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.moveToAvailable(perm)}
                  ></i>
                  <small style={{ fontSize: '14px', color: '#6c6c6c' }}>
                    {perm.method} {perm.route}
                  </small>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default branch(
  {
    session: ['session'],
    myApiUsers: ['myApiUsers'],
  },
  AddExternalApi,
);
