import React, { Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { branch } from 'baobab-react/higher-order';
import { NotificationManager } from 'react-notifications';
import SuperUserActions from '../../../actions/SuperUser';
import Loader from '../../../components/Loader';
import { emailRegex } from '../../../utils/constants';
// Actions

class UpdateExternalApi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      externalApis: [],
      available: [],
      selected: [],
      selectedRfcs: [],
      loading: false,
      loadingExternalApi: false,
      externalApi: null,
    };
  }

  async componentDidMount() {
    this.getExternalApiPermissions();
  }

  getExternalApiPermissions = async () => {
    try {
      this.setState({ loading: true });
      const response = await SuperUserActions.getExternalApiPermissions();
      if (response?.data?.success) {
        return this.setState({
          available: response.data?.permissions ?? [],
          externalApis: response.data?.externalApis ?? [],
        });
      }
      return NotificationManager.error(
        response?.data?.error || 'Error al obtener permisos y external APIs',
        'Atención',
        10000,
      );
    } catch (error) {
      this.setState({ loading: false });
      return NotificationManager.error(
        'Error al obtener permisos y external APIs',
        'Atención',
        10000,
      );
    } finally {
      this.setState({ loading: false });
    }
  };

  moveToSelected = (perm) => {
    this.setState((prevState) => ({
      available: prevState.available.filter((p) => p.id !== perm.id),
      selected: [...prevState.selected, perm],
    }));
  };

  moveToAvailable = (perm) => {
    this.setState((prevState) => ({
      available: [...prevState.available, perm],
      selected: prevState.selected.filter((p) => p.id !== perm.id),
    }));
  };

  addRfcs = () => {
    if (!this.state.rfc) {
      return NotificationManager.error(
        'RFC no puede estar vacío',
        'Atención',
        10000,
      );
    }
    this.setState((prevState) => ({
      selectedRfcs: [...prevState.selectedRfcs, prevState.rfc.toUpperCase()],
      rfc: '',
    }));
  };

  removeRfc = (rfc) => {
    this.setState((prevState) => ({
      selectedRfcs: prevState.selectedRfcs.filter((r) => r !== rfc),
    }));
  };

  handleGetExternalApi = async (id) => {
    try {
      const { available: availableState } = this.state;
      this.setState({
        loadingExternalApi: true,
      });
      const response = await SuperUserActions.getExternalApiInfoById(id);

      if (!response?.data?.success) {
        return NotificationManager.error(
          response?.data?.error || 'Error al obtener external API',
          'Atención',
          10000,
        );
      }

      const externalApi = response?.data?.myExternalApi;
      const myPermissions = externalApi?.permissions ?? [];
      const available = availableState.filter(
        (perm) => !myPermissions.some((p) => p.id === perm.id),
      );

      this.setState({
        externalApi: externalApi,
        selected: externalApi?.permissions ?? [],
        selectedRfcs: externalApi?.rfcs ?? [],
        available,
      });
    } catch (error) {
      this.setState({ loadingExternalApi: false });
    } finally {
      this.setState({ loadingExternalApi: false });
    }
  };

  handleUpdate = async (e) => {
    try {
      const { selected, selectedRfcs, externalApi } = this.state;

      if (!selectedRfcs?.length) {
        return NotificationManager.error(
          'Debes agregar al menos un RFC',
          'Atención',
          10000,
        );
      }

      this.setState({ loadingExternalApi: true });

      const response = await SuperUserActions.updateExternalApi({
        externalApiId: externalApi.externalApiId,
        permissions: selected,
        rfcs: selectedRfcs,
      });
      if (!response?.data?.success) {
        return NotificationManager.error(
          response?.data?.error || 'Error al agregar external API',
          'Atención',
          10000,
        );
      }

      // this.handleGetExternalApi(externalApi.externalApiId);
      this.getExternalApiPermissions();
      this.setState({
        selected: [],
        selectedRfcs: [],
        externalApi: null,
      });

      return NotificationManager.success(
        'API actualizada correctamente',
        'Éxito',
        10000,
      );
    } catch (error) {
      return NotificationManager.error(
        'Error al actualizar API',
        'Atención',
        10000,
      );
    } finally {
      this.setState({ loadingExternalApi: false });
    }
  };

  render() {
    const { session, location } = this.props;
    const {
      selected,
      selectedRfcs,
      available,
      loading,
      externalApi,
      loadingExternalApi,
    } = this.state;

    if (!session) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: location.pathname,
            },
          }}
        />
      );
    }

    if (loading) {
      return (
        <div className="center_loading">
          <Loader />
          <p>Cargando información</p>
        </div>
      );
    }

    return (
      <Fragment>
        <div className="external-apis-table">
          <h2>External APIs</h2>
          <table>
            <thead>
              <tr>
                <th>RFC</th>
                <th>Origen</th>
                <th>Email</th>
                <th>Actualizado</th>
              </tr>
            </thead>
            <tbody>
              {this.state.externalApis.map((item) => (
                <tr
                  key={item.id}
                  onClick={() => this.handleGetExternalApi(item.id)}
                >
                  <td>{item.rfcs?.join(', ')}</td>
                  <td>{item.origin}</td>
                  <td>{item.email}</td>
                  <td>{item.updatedAt}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {loadingExternalApi ? (
          <div className="center_loading">
            <Loader />
            <p>Cargando información</p>
          </div>
        ) : (
          externalApi && (
            <Fragment>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: 30,
                  marginTop: 30,
                }}
              >
                <button
                  type="submit"
                  className="primary"
                  onClick={this.handleUpdate}
                >
                  Finalizar y Guardar
                </button>
              </div>
              <div className="permissions-container">
                <div>
                  <div
                    style={{
                      display: 'column',
                      alignItems: 'center',
                      gap: 16,
                      width: 300,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 16,
                      }}
                    >
                      <input
                        type="text"
                        name="rfc"
                        value={this.state.rfc}
                        className="input_nc"
                        placeholder="RFC"
                        onChange={(e) => this.setState({ rfc: e.target.value })}
                      />
                      <button
                        type="submit"
                        className="primary"
                        onClick={this.addRfcs}
                      >
                        <i className="fas fa-plus-circle" />
                      </button>
                    </div>
                  </div>
                  <ul style={{ padding: 0 }}>
                    {selectedRfcs.map((rfc, i) => (
                      <li
                        key={i}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: 10,
                        }}
                      >
                        <small style={{ fontSize: '14px', color: '#6c6c6c' }}>
                          {rfc}
                        </small>
                        <i
                          className="fas fa-trash"
                          style={{ cursor: 'pointer', color: 'red' }}
                          onClick={() => this.removeRfc(rfc)}
                        ></i>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="permissions-column">
                  <h3>Permisos</h3>
                  <ul>
                    {available.map((perm) => (
                      <li
                        key={perm.id}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: 10,
                        }}
                      >
                        <small style={{ fontSize: '14px', color: '#6c6c6c' }}>
                          {perm.method} {perm.route}
                        </small>
                        <i
                          className="fas fa-arrow-right"
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.moveToSelected(perm)}
                        ></i>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="permissions-actions"></div>

                <div className="permissions-column">
                  <h3>Permisos seleccionados</h3>
                  <ul>
                    {selected.map((perm) => (
                      <li
                        key={perm.id}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: 10,
                        }}
                      >
                        <i
                          className="fas fa-arrow-left"
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.moveToAvailable(perm)}
                        ></i>
                        <small style={{ fontSize: '14px', color: '#6c6c6c' }}>
                          {perm.method} {perm.route}
                        </small>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Fragment>
          )
        )}
      </Fragment>
    );
  }
}

export default branch(
  {
    session: ['session'],
  },
  UpdateExternalApi,
);
